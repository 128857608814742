@use 'motion';
@use 'colors';
@use 'sass:color';
@import 'responsive/utils/mediaQueries.module';

.bottom {
	&::backdrop {
		background-color: rgba(
			colors.$background-overlay-color,
			$alpha: calc(var(--backdrop-opacity, 1) * (colors.$background-overlay-opacity + 1))
		);
	}

	[data-dialog-container] {
		inset-inline: 0;
		bottom: 0;
		max-width: 600px;
		width: 100%;
		margin: 0 auto;
		padding: 0;
		background-color: colors.$background-default;
		position: fixed;
		box-sizing: border-box;
		max-height: calc(100dvh - 48px);
		isolation: isolate;
		transform: translateY(100%);
	}

	&[open] {
		opacity: 1;

		[data-dialog-container] {
			transform: translateY(0);
			animation: open motion.$duration-medium2 motion.$easing-calm;
		}
	}

	&.closing {
		[data-dialog-container] {
			opacity: 0;
			animation: close motion.$duration-medium2 motion.$easing-accent;
		}
	}

	& .draggable {
		max-height: calc(100dvh - 48px);
		min-height: 25dvh;
		height: auto;
		overflow: auto;
		position: relative;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		& > main[data-type='content'] {
			flex: 1;
			overflow: auto;
		}

		&.touchable::before {
			content: '';
			position: absolute;
			top: 7px;
			width: 3.5rem;
			height: 1px;
			background-color: colors.$border-default;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			z-index: 2;
		}
	}
}

@keyframes open {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes close {
	0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(100%);
	}
}
