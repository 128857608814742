@use 'colors';

$scroll-width: 3px;

.content {
	padding-inline: var(--dialog-padding-inline);
	padding-block-end: var(--dialog-padding-block-end);
	flex: 1;
	overflow: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: auto;

	&.noPadding {
		padding: 0;
	}

	scrollbar-width: $scroll-width;

	&::-webkit-scrollbar {
		width: $scroll-width;
		height: $scroll-width;
	}

	&::-webkit-scrollbar-track {
		background: colors.$background-default;
	}

	&::-webkit-scrollbar-thumb {
		background: colors.$content-default;
	}
}
