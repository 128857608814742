@use 'motion';
@use 'colors';
@import 'responsive/utils/mediaQueries.module';

.modal {
	opacity: 0;

	[data-dialog-container] {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		max-width: 464px;
		width: 464px;
		max-height: calc(100dvh - 48px);
		position: fixed;
		background-color: colors.$background-default;
		inset-inline: unset;
		left: 50%; // RTL checked
		top: 50%;
		bottom: unset;
		transform: translate(-50%, -50%);

		@include onSmall {
			max-width: 432px;
			min-width: calc(100vw - 32px);
			width: unset;
		}
	}

	&[open] {
		opacity: 1;

		[data-dialog-container] {
			animation: open motion.$duration-medium1 motion.$easing-accent;
		}
	}

	&.closing {
		[data-dialog-container] {
			opacity: 0;
			animation: close motion.$duration-medium1 motion.$easing-accent;
		}
	}

	& .inner {
		width: 100%;
		max-height: calc(100dvh - 48px);
		height: auto;
		overflow: auto;
		display: flex;
		flex-direction: column;

		& > main[data-type='content'] {
			flex: 1;
			overflow: auto;
		}
	}

	&.fullscreen {
		[data-dialog-container] {
			@include onSmall {
				width: 100vw;
				height: 100dvh;
				max-height: 100dvh;
				max-width: 100vw;
			}
		}

		& .inner {
			height: 100dvh;
			max-height: 100dvh;
		}
	}
}

@keyframes open {
	0% {
		scale: 0.9;
		opacity: 0;
	}

	100% {
		scale: 1;
		opacity: 1;
	}
}

@keyframes close {
	0% {
		scale: 1;
		opacity: 1;
	}

	100% {
		scale: 0.9;
		opacity: 0;
	}
}
