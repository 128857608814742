@use 'colors';
@use 'texts';
@import 'responsive/utils/mediaQueries.module';

$clickable-area: 44px;
$icon-size: 20px;
$icon-offset: ($clickable-area - $icon-size) / 2;

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	padding: 2rem;
	background-color: colors.$background-default;

	&.hasSubtitle {
		align-items: flex-start;
	}

	&.empty {
		max-height: calc($icon-size + 1rem);
	}

	@include onSmall {
		padding: 1rem;

		&.barPadding {
			padding: 2rem 1rem 1rem;
		}
	}

	.fill {
		flex: 1;
	}

	.title {
		margin: 0;

		@extend %text-title-m;
	}

	.subtitle {
		margin: 0;

		@extend %text-body-m;
	}

	.close,
	.back,
	.minus {
		width: $icon-size;
		height: $icon-size;
		position: relative;

		.area {
			align-items: center;
			width: $clickable-area;
			height: $clickable-area;
			top: -$icon-offset;
			position: absolute;
		}
	}

	.alternative {
		margin-inline-start: 1rem;
	}

	.close,
	.minus {
		margin-inline-start: $icon-offset; // 44px - 20px  / 2 = 12px;

		.area {
			inset-inline-end: -$icon-offset;
		}
	}

	.back {
		margin-inline-end: $icon-offset; // 44px - 20px  / 2 = 12px;

		.area {
			inset-inline-start: -$icon-offset;
		}
	}
}
