@use 'colors';
@import 'responsive/utils/mediaQueries.module';

.dialog {
	visibility: hidden;
	inset: unset;
	position: fixed;
	max-width: unset;
	max-height: unset;
	top: 0;
	inset-inline-start: 0;
	border: unset;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	isolation: isolate;
	width: 100vw;
	height: 100dvh;
	background-color: colors.$background-overlay;

	--dialog-padding-inline: 2rem;
	--dialog-padding-block-end: 2rem;

	@include onSmall {
		--dialog-padding-inline: 1rem;
		--dialog-padding-block-end: 1rem;
	}

	&.nonModal {
		top: unset;
		inset-inline-start: unset;
		width: auto;
		height: auto;
	}

	&[open] {
		visibility: visible;
	}
}

// Body scroll is locked when dialog is open
body:has(.dialog.modal[open]) {
	position: relative;
	overflow: hidden;
	padding-top: 0;
	padding-inline: 0;
	margin-top: 0;
	margin-inline: 0;
	scrollbar-gutter: stable both-edges;

	// iOS
	touch-action: none;
	-webkit-overflow-scrolling: none;

	// Other browsers
	overscroll-behavior: contains;
}
